<template>
  <div class="wrapper">
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <div id="app">
      <ul id="menu" style="background-color:#000"></ul>
      <v-container fluid grid-list-md fill-height style="padding:3%">
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="direita">
              <a
                href="http://enercred.com.br"
                target="_blank"
                alt="Enercred - Energia Limpa e Barata"
              >
                <img
                  src="../../assets/logo-enercred-branco.png"
                  width="50%"
                  style="padding: 5px"
                  alt="Enercred - Energia Limpa e Barata"
                  class="src"
                />
              </a>
              <div>
                <span class="display-1 white--text font-weight-bold hidden-md-and-up">Economia de Verdade!</span>
                <span class="display-2 white--text font-weight-bold hidden-sm-and-down">Economia de Verdade!</span>
              </div>
              <div
                style="color:#FFF"
                class="title pt-4"
              >Novas usinas já estão em construção, fique atento no seu email e whatsapp para saber quando os créditos chegarão em sua conta.</div>
            </div>
            <div class="hidden-sm-and-down"><cpn-redes-sociais/></div>
          </v-flex>
          <v-flex
            xs12
            sm12
            md6
            class="pt-3 mt-4"
            style="vertical-align: bottom; vertical-align: text-bottom;"
          >
            <v-card
              class="mx-auto pa-4"
              color="#26c6da"
              dark
              max-width="500"
              style="border-radius: 10px"
            >
              <v-card-title>
                <span class="headline font-weight-bold">Baixe nossos aplicativos</span>
              </v-card-title>

              <v-card-text class="title pt-1 pb-3">Acompanhe o seu consumo e economia mês a mês</v-card-text>
              <div style="display: flex;flex-direction: row;justify-content: center;">
                <a
                  href="https://play.google.com/store/apps/details?id=com.enecred.enercredapp"
                  target="_blank"
                  style="padding-right: 5px"
                >
                  <v-img style="min-width: 150px;" :src="require('@/assets/img/apps/android.png')"></v-img>
                </a>
                <a
                  href="https://apps.apple.com/us/app/enercred/id1465641135"
                  target="_blank"
                  style="padding-left: 5px"
                >
                  <v-img style="min-width: 150px" :src="require('@/assets/img/apps/app-store.png')"></v-img>
                </a>
              </div>
              <div class="pt-4">
                ou acesse por
                <v-btn class="black" href="https://super.enercred.com.br">Login</v-btn>
              </div>
            </v-card>
            <div class="hidden-sm-and-up"><cpn-redes-sociais/></div>
            <img
              src="../../assets/fundo.png"
              width="100%"
              class="hidden-sm-and-down"
              style="display: inline-block; margin-top: -100px"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import RedesSociais from '../../components/RedesSociais'
export default {
  components: {
    cpnRedesSociais: RedesSociais
  },
  mounted() {
    localStorage.removeItem('lead')
    localStorage.removeItem('newUser')
  },
};
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: rgb(51, 51, 51);
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
/*
.formulario {
    height: 600px;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    background-color:#FFF;
    padding: 10px 50px 10px 50px;
    text-align: center;
    border-radius: 10px;
}
*/
.direita {
  width: 95%;
  padding-top: 10%;
  text-align: left;
}
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #35495e;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #35495e;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}

.signUp {
  margin-top: 60px;
}
input {
  margin: 10px 0;
  width: 20%;
  padding: 15px;
}
button {
  margin-top: 10px;
  width: 10%;
  cursor: pointer;
}
span {
  display: block;
  margin-top: 20px;
  font-size: 11px;
}
.form_title {
  font-size: 2em;
  color: rgb(99, 97, 97);
  font-weight: bold;
}

.wrapper {
  background-color: #00a8bb;
  min-height: 100vh;
}
</style>

